import React, { useState } from 'react';
import styled from 'styled-components';
import Button from '../shared/Button';

interface SuccessModalProps {
  mainMessage: string;
  subMessage: string;
  onDismiss: () => void;
  inviteUrl: string;
}

const ModalOverlay = styled.div`
  inset: 0;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ModalContent = styled.div`
  background-color: rgb(38, 38, 38);
  border-radius: 0.5rem;
  text-align: center;
  position: relative;
  max-width: 90%;
  width: 400px;
  color: white;
`;

const Message = styled.p`
  font-size: 1.125rem;
  font-weight: 600;
  margin-bottom: 0.5rem;
`;

const SubMessage = styled.p`
  font-size: 0.875rem;
  margin-bottom: 1rem;
`;

const UrlContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: rgb(38, 38, 38);
  border-radius: 0.25rem;
  border: 1px solid rgb(64 64 64);
  padding: 0.5rem;
  margin-top: 1rem;
`;

const UrlText = styled.span`
  font-size: 0.875rem;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  flex-grow: 1;
  margin-right: 0.5rem;
`;

const CopyButton = styled(Button)`
  color: white;
  font-size: 0.875rem;
  padding: 0.25rem 0.5rem;
  border-radius: 0.25rem;
  border: none;
  cursor: pointer;
  transition: background-color 0.2s;

  &:disabled {
    cursor: not-allowed;
  }
`;

const SuccessModal: React.FC<SuccessModalProps> = ({
  mainMessage,
  subMessage,
  onDismiss,
  inviteUrl,
}) => {
  const [copied, setCopied] = useState(false);

  const handleCopy = () => {
    console.log('Copying invite URL:', inviteUrl);
    navigator.clipboard
      .writeText(inviteUrl)
      .then(() => {
        console.log('URL copied to clipboard');
        setCopied(true);
        setTimeout(() => {
          console.log('Resetting copied state');
          setCopied(false);
        }, 2000);
      })
      .catch((err) => {
        console.error('Error copying to clipboard:', err);
      });
  };

  console.log('Rendering SuccessModal, copied state:', copied);

  return (
    <ModalOverlay onClick={onDismiss}>
      <ModalContent onClick={(e) => e.stopPropagation()}>
        <div className="sm:block hidden absolute -top-80 left-1/2 transform -translate-x-1/2 text-[12rem] filter drop-shadow-lg">
          ☁️
        </div>
        <div className="sm:block hidden absolute -top-16 left-1/2 transform -translate-x-1/2 text-6xl filter drop-shadow-lg">
          ✉️
        </div>

        <Message>{mainMessage}</Message>
        <SubMessage>{subMessage}</SubMessage>
        {inviteUrl && (
          <UrlContainer>
            <UrlText>{inviteUrl}</UrlText>
            <CopyButton onClick={handleCopy} disabled={copied}>
              {copied ? 'Copied!' : 'Copy'}
            </CopyButton>
          </UrlContainer>
        )}
      </ModalContent>
    </ModalOverlay>
  );
};

export default SuccessModal;
