import { Switch } from '@mantine/core';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  FaBook,
  FaCopy,
  FaEnvelope,
  FaPen,
  FaRocket,
  FaScroll,
  FaSearch,
  FaSun,
  FaSync,
  FaUserCheck,
  FaUserNinja,
} from 'react-icons/fa';
import { Channel } from 'stream-chat';
import { useChatContext } from 'stream-chat-react';
import { Invitation, useInvitations } from '../../hooks/useInvitations';
import { supabase } from '../../supabaseClient';
import ChatModal from '../ChatModal/ChatModal';
import InviteFriends from '../InviteFriends/InviteFriends';
import Button from '../shared/Button';
import Input from '../shared/Input';
import './AddressBook.css';
// import { Switch } from '../../@/components/ui/switch';
import {
  Card,
  CardContent,
  CardHeader,
  CardTitle,
} from '../../@/components/ui/card';

type SortBy = 'name' | 'email' | 'status';

type Persona = {
  id: string;
  name: string;
  description: string;
  icon: React.ReactNode;
};

const getErrorMessage = (error: unknown): string => {
  if (error instanceof Error) return error.message;
  return String(error);
};

interface AddressBookProps {
  invitations: Invitation[];
  isLoading: boolean;
  error: string | null;
  refreshInvitations: () => void;
  sendInvitation: (
    email: string,
  ) => Promise<{ success: boolean; message: any }>;
  updateInvitationStatus: (
    invitationId: string,
    status: 'accepted' | 'rejected',
  ) => Promise<
    | {
        success: boolean;
        message?: undefined;
      }
    | { success: boolean; message: string }
  >;
  onNavigateToChat: (channelId: string) => void;
}

const AddressBook: React.FC<AddressBookProps> = ({
  invitations,
  isLoading,
  error,
  refreshInvitations,
  sendInvitation,
  updateInvitationStatus,
  onNavigateToChat,
}) => {
  const [searchTerm, setSearchTerm] = useState('');
  const [sortBy, setSortBy] = useState<'name' | 'email' | 'status'>('name');
  const [selectedContact, setSelectedContact] = useState<Invitation | null>(
    null,
  );
  const [showInviteModal, setShowInviteModal] = useState(false);
  const { client } = useChatContext();

  const [contacts, setContacts] = useState<any[]>([]);
  const [isLoadingContacts, setIsLoadingContacts] = useState(false);
  const [contactsError, setContactsError] = useState<string | null>(null);

  const [activeChatChannel, setActiveChatChannel] = useState<Channel | null>(
    null,
  );
  const [showChatModal, setShowChatModal] = useState(false);

  const { joinChannel } = useInvitations();

  const [lastInviteUrl, setLastInviteUrl] = useState<string | null>(null);

  const [activePersonas, setActivePersonas] = useState<Set<string>>(new Set());

  const { t } = useTranslation();

  const [personas, setPersonas] = useState<Persona[]>([
    {
      id: 'elon-musk',
      name: t('elon-musk'),
      description: t('elon-muskDescription'),
      icon: <FaRocket className="h-6 w-6" />,
    },
    {
      id: 'eckhart-tolle',
      name: t('eckhart-tolle'),
      description: t('eckhart-tolleDescription'),
      icon: <FaSun className="h-6 w-6" />,
    },
    {
      id: 'wittgenstein',
      name: t('wittgenstein'),
      description: t('wittgensteinDescription'),
      icon: <FaBook className="h-6 w-6" />,
    },
    {
      id: 'junichiro-tanizaki',
      name: t('junichiro-tanizaki'),
      description: t('junichiro-tanizakiDescription'),
      icon: <FaPen className="h-6 w-6" />,
    },
    {
      id: 'oda-nobunaga',
      name: t('oda-nobunaga'),
      description: t('oda-nobunagaDescription'),
      icon: <FaUserNinja className="h-6 w-6" />,
    },
    {
      id: 'fukuzawa-yukichi',
      name: t('fukuzawa-yukichi'),
      description: t('fukuzawa-yukichiDescription'),
      icon: <FaScroll className="h-6 w-6" />,
    },
  ]);

  useEffect(() => {
    const fetchPersonas = async () => {
      const { data, error } = await supabase
        .from('user_personas')
        .select('*')
        .eq('user_id', client.userID);

      if (error) {
        console.error('Error fetching personas:', error);
        return;
      }

      setPersonas((prev) =>
        prev.map((persona) => ({
          ...persona,
          isActive:
            data.find((p) => p.persona_id === persona.id)?.is_active ?? false,
        })),
      );
    };

    fetchPersonas();
  }, []);

  console.log('personas', personas);

  const [isLoadingPersonas, setIsLoadingPersonas] = useState(true);
  const [personasError, setPersonasError] = useState<string | null>(null);

  const fetchContacts = useCallback(async () => {
    console.log('Fetching contacts for user:', client.userID);
    setIsLoadingContacts(true);
    setContactsError(null);
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/api/connections/${client.userID}`,
      );
      console.log('Fetch response status:', response.status);
      console.log('Response headers:', response.headers);

      // Log the raw response text
      const responseText = await response.text();
      console.log('Raw response text:', responseText);

      if (!response.ok) {
        throw new Error(
          `Failed to fetch contacts: ${response.status} ${response.statusText}`,
        );
      }

      const contentType = response.headers.get('content-type');
      if (!contentType || !contentType.includes('application/json')) {
        console.error('Unexpected content type:', contentType);
        throw new Error('Received non-JSON response from server');
      }

      // Try to parse the response as JSON
      let data;
      try {
        data = JSON.parse(responseText);
      } catch (parseError) {
        console.error('Error parsing JSON:', parseError);
        throw new Error('Failed to parse server response as JSON');
      }

      console.log('Parsed contacts data:', data);
      console.log('Contact structure:', JSON.stringify(data[0], null, 2));

      setContacts(data);
    } catch (error) {
      console.error('Error fetching contacts:', error);
      setContactsError(
        `Failed to load contacts. Please try again. ${getErrorMessage(error)}`,
      );
    } finally {
      setIsLoadingContacts(false);
    }
  }, [client.userID]);

  // Combine fetchContacts and refreshInvitations into a single function
  const refreshAllData = useCallback(async () => {
    console.log('Refreshing all data...');
    setIsLoadingContacts(true);
    setContactsError(null);

    try {
      await Promise.all([fetchContacts(), refreshInvitations()]);
    } catch (error) {
      console.error('Error refreshing data:', error);
      setContactsError(
        `Failed to refresh data. Please try again. ${getErrorMessage(error)}`,
      );
    } finally {
      setIsLoadingContacts(false);
    }
  }, [fetchContacts, refreshInvitations]);

  // Use useEffect to automatically refresh data when the component mounts
  useEffect(() => {
    console.log('AddressBook component mounted, refreshing all data');
    refreshAllData();
  }, [refreshAllData]);

  // Set up an interval to refresh data periodically (e.g., every 5 minutes)
  useEffect(() => {
    const intervalId = setInterval(
      () => {
        console.log('Periodic refresh triggered');
        refreshAllData();
      },
      5 * 60 * 1000,
    ); // 5 minutes in milliseconds

    return () => clearInterval(intervalId);
  }, [refreshAllData]);

  // Use useCallback to memoize the refreshInvitations function
  const memoizedRefreshInvitations = useCallback(() => {
    refreshInvitations();
  }, [refreshInvitations]);

  // Update the useEffect to use the memoized function
  useEffect(() => {
    memoizedRefreshInvitations();
  }, [memoizedRefreshInvitations]);

  const handleInviteSent = useCallback(
    (inviteUrl: string) => {
      memoizedRefreshInvitations();
      setLastInviteUrl(inviteUrl);
    },
    [memoizedRefreshInvitations],
  );

  const handleCopyInviteUrl = useCallback((url: string) => {
    navigator.clipboard.writeText(url).then(() => {
      console.log('Invite URL copied to clipboard');
      // You can add a toast notification here if you want
    });
  }, []);

  const handleInviteModalClose = useCallback(() => {
    setShowInviteModal(false);
    memoizedRefreshInvitations();
  }, [memoizedRefreshInvitations]);

  const handleCloseDetails = useCallback(() => {
    setSelectedContact(null);
  }, []);

  const handleContactClick = useCallback((invitation: Invitation) => {
    setSelectedContact(invitation);
  }, []);

  const filteredInvitations = useMemo(
    () =>
      invitations.filter(
        (inv) =>
          inv?.invitee_email
            ?.toLowerCase()
            .includes(searchTerm.toLowerCase()) ||
          inv?.inviter_id?.toLowerCase().includes(searchTerm.toLowerCase()),
      ),
    [invitations, searchTerm],
  );
  const sortedInvitations = useMemo(
    () =>
      [...filteredInvitations].sort((a, b) => {
        if (sortBy === 'name' || sortBy === 'email') {
          return (a?.invitee_email ?? '').localeCompare(b?.invitee_email ?? '');
        }
        return (a?.status ?? '').localeCompare(b?.status ?? '');
      }),
    [filteredInvitations, sortBy],
  );

  const acceptedInvitations = useMemo(
    () => sortedInvitations.filter((inv) => inv?.status === 'accepted'),
    [sortedInvitations],
  );

  const pendingReceivedInvitations = useMemo(() => {
    return sortedInvitations.filter(
      (inv) =>
        inv?.status === 'pending' && inv?.invitee_email === client.user?.email,
    );
  }, [sortedInvitations, client.user?.email]);

  const [
    pendingReceivedInvitationsWithEmail,
    setPendingReceivedInvitationsWithEmail,
  ] = useState<Invitation[] & { email: string }[]>([]);

  useEffect(() => {
    const addEmail = async () => {
      const newInvitations: (Invitation & { email: string })[] = [];
      setPendingReceivedInvitationsWithEmail([]);

      pendingReceivedInvitations.forEach(async (inv) => {
        const newInv = { ...inv, email: '' };
        const user = await supabase
          .from('profiles')
          .select('email')
          .eq('supabase_user_id', inv.inviter_id)
          .single();
        newInv.email = user.data?.email;
        newInvitations.push(newInv);
        setPendingReceivedInvitationsWithEmail((prev) => [...prev, newInv]);
      });
    };

    addEmail();
  }, [pendingReceivedInvitations]);

  const pendingSentInvitations = useMemo(
    () =>
      sortedInvitations
        .filter(
          (inv) =>
            inv?.status === 'pending' && inv?.inviter_id === client.userID,
        )
        .sort((a, b) => {
          // Sort by created_at in descending order (most recent first)
          return (
            new Date(b.created_at).getTime() - new Date(a.created_at).getTime()
          );
        }),
    [sortedInvitations, client.userID],
  );

  const handleContactAction = async (
    contact: Invitation | any,
    type: 'accepted' | 'pending' | 'sent' | 'contact',
  ) => {
    if (type === 'accepted' || type === 'contact') {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_URL}/api/connections/get-connection-info`,
          {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
              userId: client.userID,
              contactId: contact.id,
            }),
          },
        );
        const { data } = await response.json();

        let channelId = data.channel_id;

        console.log('Channel ID before joining:', channelId);

        if (!channelId) {
          throw new Error('Invalid contact: missing channel ID');
        }

        // Join the channel
        const channel = await joinChannel(channelId);
        console.log('Joined channel:', channel);

        // Navigate to the main chat page with the channel ID
        onNavigateToChat(channelId);
        console.log('Navigated to chat with channel ID:', channelId);
      } catch (error) {
        console.error('Error joining channel or navigating to chat:', error);
        setContactsError(
          error instanceof Error
            ? error.message
            : 'An unknown error occurred. Please try again.',
        );
      }
    } else if (type === 'pending') {
      try {
        console.log(
          'Accepting invitation for contact with channel ID:',
          contact.channel_id,
        ); // Log channel_id before accepting
        await updateInvitationStatus(contact.id, 'accepted');
        console.log(
          'Accepted invitation for contact with channel ID:',
          contact.channel_id,
        ); // Log channel_id after accepting
      } catch (error) {
        console.error('Error accepting invitation:', error);
        setContactsError(
          `Failed to accept invitation. Please try again. ${getErrorMessage(error)}`,
        );
      }
    } else if (type === 'sent') {
      try {
        console.log(
          'Rejecting invitation for contact with channel ID:',
          contact.channel_id,
        ); // Log channel_id before rejecting
        await updateInvitationStatus(contact.id, 'rejected');
        console.log(
          'Rejected invitation for contact with channel ID:',
          contact.channel_id,
        ); // Log channel_id after rejecting
      } catch (error) {
        console.error('Error rejecting invitation:', error);
        setContactsError(
          `Failed to reject invitation. Please try again. ${getErrorMessage(error)}`,
        );
      }
    }
    memoizedRefreshInvitations();
  };

  const handleSortingChange = useCallback((sorting: string) => {
    setSortBy(sorting as SortBy);
  }, []);

  const sortingOptions = useMemo(
    () => [
      { value: 'name', label: t('sortByName') },
      { value: 'email', label: t('sortByEmail') },
      { value: 'status', label: t('sortByStatus') },
    ],
    [t],
  );

  // Update the handleRefresh function to use refreshAllData
  const handleRefresh = useCallback(() => {
    refreshAllData();
  }, [refreshAllData]);

  useEffect(() => {
    const fetchUserPersonas = async () => {
      setIsLoadingPersonas(true);
      setPersonasError(null);
      const { data, error } = await supabase
        .from('user_personas')
        .select('persona_id')
        .eq('user_id', client.userID)
        .eq('is_active', true);

      if (error) {
        console.error('Error fetching user personas:', error);
        setPersonasError('Failed to load personas. Please try again.');
      } else {
        const activePersonaIds = new Set(data.map((row) => row.persona_id));
        setActivePersonas(activePersonaIds);
      }
      setIsLoadingPersonas(false);
    };

    fetchUserPersonas();
  }, [client.userID]);

  const togglePersona = useCallback(
    async (id: string, isChecked: boolean) => {
      /* const { error } = await supabase.from('user_personas').upsert(
        {
          persona_id: id,
          is_active: isChecked,
        },
        { onConflict: 'user_id,persona_id' },
      ); */

      const { data, error } = await supabase
        .from('user_personas')
        .update({ is_active: isChecked })
        .eq('user_id', client.userID)
        .eq('persona_id', id)
        .select();

      if (error) {
        console.error('Error updating user persona:', error);
        // Revert the local state change if there was an error
        return;
      }

      const newActivePersonas = new Set(activePersonas);
      const isCurrentlyActive = newActivePersonas.has(id);

      if (isCurrentlyActive) {
        newActivePersonas.delete(id);
      } else {
        newActivePersonas.add(id);
      }

      // Update the local state
      setActivePersonas(newActivePersonas);
    },
    [activePersonas, client.userID],
  );

  const renderContactCard = (
    item: Invitation | any,
    type: 'accepted' | 'pending' | 'sent' | 'contact',
  ) => (
    <div
      key={item.id}
      className="contact-card"
      onClick={() => handleContactClick(item)}
    >
      <div className="flex">
        <div className="contact-avatar">
          {(item.invitee_email || item.email)?.charAt(0).toUpperCase() ?? '?'}
        </div>
        <div className="contact-info">
          <div className="contact-email">
            {type === 'contact'
              ? item.name
              : item.invitee_email || item.email || t('unknownEmail')}
          </div>
          <div className="contact-status">{item.email}</div>
        </div>
      </div>
      <div className="contact-actions">
        <Button
          className="ml-2"
          onClick={(e) => {
            e.stopPropagation();
            handleContactAction(item, type);
          }}
        >
          {type === 'accepted' || type === 'contact'
            ? t('message')
            : type === 'pending'
              ? t('accept')
              : t('cancel')}
        </Button>
        {type === 'sent' && item.invite_url && (
          <Button
            className="ml-2"
            onClick={(e) => {
              e.stopPropagation();
              handleCopyInviteUrl(item.invite_url);
            }}
          >
            <FaCopy /> {t('copyURL')}
          </Button>
        )}
      </div>
    </div>
  );

  const renderInvitationCard = (
    item: Invitation | any,
    type: 'accepted' | 'pending' | 'sent' | 'contact',
  ) => (
    <div
      key={item.id}
      className="contact-card"
      onClick={() => handleContactClick(item)}
    >
      <div className="contact-avatar">
        {item.email?.charAt(0).toUpperCase() ?? '?'}
      </div>
      <div className="contact-info">
        <div className="contact-email">{item.email || t('unknownEmail')}</div>
        <div className="contact-status">
          {type === 'contact' ? 'Connected' : item.status || 'Unknown Status'}
        </div>
      </div>
      <div className="contact-actions">
        <Button
          className="ml-2"
          onClick={async (e) => {
            e.stopPropagation();
            // handleContactAction(item, type);
            // Create the connection here
            try {
              await fetch(
                process.env.REACT_APP_API_URL +
                  '/api/connections/accept-connection',
                {
                  method: 'POST',
                  headers: { 'Content-Type': 'application/json' },
                  body: JSON.stringify({
                    inviter: item.inviter_id,
                    invitee: client.userID,
                    invitee_email: item.invitee_email,
                  }),
                },
              );

              refreshInvitations();
            } catch (error) {
              console.error('Error accepting invitation:', error);
            }
          }}
        >
          {type === 'accepted' || type === 'contact'
            ? t('message')
            : type === 'pending'
              ? t('accept')
              : t('cancel')}
        </Button>
      </div>
    </div>
  );

  if (isLoading || isLoadingContacts) {
    return (
      <div className="address-book-container w-full h-full flex items-center justify-center">
        <div className="loading-spinner">
          <div className="spinner"></div>
          <p>{t('loadingContacts')}</p>
        </div>
      </div>
    );
  }

  return (
    <div className="address-book-container">
      <div className="address-book-main">
        <div className="address-book-header">
          <h2 className="text-2xl font-bold mb-5">{t('addressBook')}</h2>
          <Button onClick={handleRefresh} className="refresh-button">
            <FaSync /> {t('refresh')}
          </Button>
        </div>
        <div className="search-and-sort">
          <Input
            value={searchTerm}
            onChange={(value) => setSearchTerm(value)}
            Icon={<FaSearch />}
            placeholder={t('searchContacts')}
          />
        </div>
        {error && <div className="error-message">{error}</div>}
        {contactsError && <div className="error-message">{contactsError}</div>}
        <div className="contacts-sections">
          <div className="section">
            <h3 className="section-header">
              <FaUserCheck /> {t('contacts')}
            </h3>
            <div className="contact-list">
              {contacts.length > 0 ? (
                contacts.map((contact) => renderContactCard(contact, 'contact'))
              ) : (
                <p className="no-contacts">{t('noContacts')}</p>
              )}
            </div>
          </div>
          <div className="section">
            <h3 className="section-header">
              <FaEnvelope /> {t('pendingInvitations')}
            </h3>
            <div className="contact-list">
              {pendingSentInvitations.length > 0 ? (
                pendingSentInvitations.map((inv) =>
                  renderContactCard(inv, 'sent'),
                )
              ) : (
                <p className="no-contacts">{t('noPendingInvitations')}</p>
              )}
            </div>
          </div>
          <div className="section">
            <h3 className="section-header">
              <FaEnvelope /> {t('receivedInvitations')}
            </h3>
            <div className="contact-list">
              {pendingReceivedInvitationsWithEmail.length > 0 ? (
                pendingReceivedInvitationsWithEmail.map((inv) =>
                  renderInvitationCard(inv, 'pending'),
                )
              ) : (
                <p className="no-contacts">{t('noReceivedInvitations')}</p>
              )}
            </div>
          </div>
          <Card className="mt-1 bg-[#2d2d2d] text-white">
            <CardHeader>
              <CardTitle className="flex items-center text-2xl font-bold">
                <FaUserCheck className="mr-2" /> {t('aiPersonas')}
              </CardTitle>
            </CardHeader>
            <CardContent>
              <ul className="space-y-6">
                {personas.map((persona) => (
                  <li
                    key={persona.id}
                    className="flex items-center justify-between p-4 rounded-lg bg-[#2d2d2d]"
                  >
                    <div className="flex items-center space-x-4">
                      <div className={`p-2 rounded-full bg-gray-700`}>
                        <div
                          className={`${activePersonas.has(persona.id) ? 'text-cyan-600' : 'text-white'}`}
                        >
                          {persona.icon}
                        </div>
                      </div>
                      <div>
                        <h3 className="text-lg font-semibold">
                          {persona.name}
                        </h3>
                        <p className="text-sm text-gray-400">
                          {persona.description}
                        </p>
                      </div>
                    </div>
                    <div className="flex items-center space-x-2">
                      <Switch
                        checked={activePersonas.has(persona.id)}
                        onChange={(event) =>
                          togglePersona(persona.id, event.target.checked)
                        }
                        aria-label={t('togglePersona', { name: persona.name })}
                      />
                    </div>
                  </li>
                ))}
              </ul>
            </CardContent>
          </Card>
        </div>
      </div>
      {showInviteModal && (
        <InviteFriends
          onClose={handleInviteModalClose}
          onError={(errorMessage) => console.error(errorMessage)}
          onInviteSent={handleInviteSent}
          isOverlay={true}
          refreshInvitations={refreshInvitations}
        />
      )}
      {showChatModal && activeChatChannel && (
        <ChatModal
          channel={activeChatChannel}
          onClose={() => {
            setShowChatModal(false);
            setActiveChatChannel(null);
          }}
        />
      )}
    </div>
  );
};

export default AddressBook;
