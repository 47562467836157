import to from 'await-to-js';
import axios from 'axios';
import { AnimatePresence, motion } from 'framer-motion';
import React, { useCallback, useEffect, useState } from 'react';
import { useIntersection } from 'react-use';
import { logChatPromiseExecution, Message } from 'stream-chat';
import {
  defaultRenderMessages,
  MessageInput,
  MessageInputFlat,
  MessageList,
  MessageRenderer,
  MessageSimple,
  Thread,
  useChannelActionContext,
  useChannelStateContext,
  useChatContext,
  useMessageContext,
  useMessageInputContext,
  useUserRole,
  Window,
} from 'stream-chat-react';
import { MessageInputProps } from 'stream-chat-react/dist/components/MessageInput/MessageInput';
import { encodeToMp3 } from 'stream-chat-react/mp3-encoder';
import { MAX_MESSAGE_LENGTH } from '../../@/lib/constants';
import { cn } from '../../@/lib/utils';
import { MessagingChannelHeader } from '../../components';
import { useGiphyContext } from '../../context';
import { useChatLanguage } from '../../context/ChatLanguageContext';
import { supabase } from '../../supabaseClient';
import type { StreamChatGenerics } from '../../types';
import { calculateAge } from '../../utils/dateUtils';
import { getApiUrl } from '../../utils/environment';
import { MessageListTypingIndicator } from '../TypingIndicator/MessageListTypingIndicator';
import TypewriterEffect from './TypewriterEffect';

export type ChannelInnerProps = {
  toggleMobile: () => void;
  theme: string;
};

const CustomInputComponent = ({
  setDisabledInput,
}: {
  setDisabledInput: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  const [characterCount, setCharacterCount] = useState(0);
  const { text } = useMessageInputContext();

  useEffect(() => {
    setCharacterCount(text.length);
    if (text.length >= MAX_MESSAGE_LENGTH) {
      setDisabledInput(true);
    } else {
      setDisabledInput(false);
    }
  }, [text]);

  const isMaxLength = characterCount >= MAX_MESSAGE_LENGTH;
  const shouldShowMaxLengthWarning = characterCount >= MAX_MESSAGE_LENGTH - 100;

  return (
    <div className="w-full relative">
      <MessageInputFlat />{' '}
      {shouldShowMaxLengthWarning && (
        <p
          className={cn(
            'inline-flex items-center gap-1.5 py-1.5 px-3 rounded-md text-xs font-medium bg-gray-100 text-gray-800 dark:bg-neutral-800 dark:text-neutral-200',
            'absolute right-[135px] top-[15px] opacity-50',
            isMaxLength && 'bg-red-500 text-white opacity-100 right-[65px]',
          )}
        >
          {characterCount}/{MAX_MESSAGE_LENGTH}
        </p>
      )}
    </div>
  );
};

const CustomRenderText = ({
  text = '',
  messages,
  // language,
}: {
  text?: string;
  messages: Message[];
  // language: string;
}) => {
  const { message } = useMessageContext();
  const { isMyMessage } = useUserRole(message);
  const { chatLanguage } = useChatLanguage();

  const actualLanguage =
    chatLanguage.substring(0, 2).toLowerCase() === 'or'
      ? 'text'
      : chatLanguage.substring(0, 2);
  const textToDisplay = chatLanguage.includes('AI')
    ? message[actualLanguage]
    : isMyMessage
      ? (message.originalText as string)
      : (message[actualLanguage] as string);

  const intersectionRef = React.useRef(null);
  const intersection = useIntersection(intersectionRef, {
    root: null,
    rootMargin: '0px',
    threshold: 0,
  });

  useEffect(() => {
    if (intersection?.isIntersecting && message.translationError) {
      const apiUrl = getApiUrl();
      console.log(`Translate message again, its broken!`);

      const currentMessageIndex = messages.findIndex(
        (msg) => msg.id === message.id,
      );

      const context = messages.slice(
        Math.max(0, currentMessageIndex - 10),
        currentMessageIndex,
      );

      // We don't care about the result
      try {
        axios.post(`${apiUrl}/translate/try-translate-message`, {
          message,
          targetLanguages: ['EN', 'JA'],
          context,
        });
      } catch (err) {}
    }
  }, [intersection?.isIntersecting]);

  return (
    <div ref={intersectionRef}>
      <AnimatePresence mode="wait">
        <div>
          <TypewriterEffect
            text={(textToDisplay as string) || ''}
            typingSpeed={75}
            isLastMessage={messages[messages.length - 1].id === message.id}
          />
        </div>
      </AnimatePresence>
    </div>
  );
};

const ChannelInner = (props: ChannelInnerProps) => {
  const { theme, toggleMobile } = props;
  const { giphyState, setGiphyState } = useGiphyContext();
  const { client } = useChatContext();
  const { channel } = useChannelStateContext();
  const { sendMessage } = useChannelActionContext<StreamChatGenerics>();
  const { chatLanguage, setChatLanguage } = useChatLanguage();
  const [messages, setMessages] = useState<any[]>([]);
  console.log('messages:', messages);
  const [previousMessages, setPreviousMessages] = useState<
    Array<{ text: string; userName: string; gender?: string; age?: number }>
  >([]);
  const [userProfile, setUserProfile] = useState<{
    gender?: string;
    birthday?: string;
    native_language?: string;
  } | null>(null);

  useEffect(() => {
    setPreviousMessages(() => {
      const updatedMessages = messages
        .map((msg) => ({
          ...msg,
          text: msg[chatLanguage] || msg.text,
        }))
        .slice(-10);
      return updatedMessages;
    });
  }, [userProfile, messages]);

  useEffect(() => {
    const fetchUserProfile = async () => {
      if (client.userID) {
        const { data, error } = await supabase
          .from('profiles')
          .select('gender, birthday, native_language')
          .eq('supabase_user_id', client.userID)
          .single();

        if (error) {
          console.error('Error fetching user profile:', error);
        } else {
          if (!chatLanguage) setChatLanguage(data.native_language);
          setUserProfile(data);
        }
      }
    };

    fetchUserProfile();
  }, [client.userID]);

  useEffect(() => {
    const updateMessages = () => {
      const updatedMessages = channel.state.messages.map((msg: any) => ({
        ...msg,
        text: msg[chatLanguage] || msg.text,
      }));
      setMessages(updatedMessages);
    };

    updateMessages();
    channel.on('message.new', () => {
      const updatedMessages = channel.state.messages.map((msg: any) => ({
        translationSuccess: false,
        translationError: false,
        text: msg[chatLanguage] || msg.text,
        ...msg,
      }));
      setMessages(updatedMessages);
    });
    channel.on('message.updated', updateMessages);
    channel.on('message.deleted', updateMessages);

    return () => {
      channel.off('message.new', updateMessages);
      channel.off('message.updated', updateMessages);
    };
  }, [channel, chatLanguage]);

  const translateMessage = async (
    text: string,
    userName: string,
    lastMessage?: Message,
  ) => {
    if (lastMessage?.translationSuccess)
      return {
        translationSuccess: true,
        translationError: false,
      };
    const [err, translations] = await to(
      callTranslationAPI(text, userName, lastMessage),
    );

    if (err) {
      return {
        EN: text,
        JA: 'Translation failed',
        translationError: true,
        translationSuccess: false,
      };
    }

    return {
      // @ts-ignore
      ...translations,
      translationSuccess: true,
      translationError: false,
    };
  };

  const callTranslationAPI = async (
    text: string,
    userName: string,
    lastMessage?: Message,
  ) => {
    return new Promise(async (resolve, reject) => {
      try {
        const apiUrl = getApiUrl();
        const response = await axios.post(`${apiUrl}/translate`, {
          text,
          userName,
          targetLanguages: ['EN', 'JA'],
          messages: lastMessage
            ? messages.slice(-9).concat(lastMessage)
            : messages.slice(-10),
          gender: userProfile?.gender,
          age: userProfile?.birthday
            ? calculateAge(userProfile.birthday)
            : undefined,
        });
        console.log('Translation API response:', response.data);
        resolve(response.data.translations);
      } catch (error) {
        console.error('Error calling translation API:', error);
        reject(error);
      }
    });
  };

  const transcribeAudio = useCallback(
    async (file: string) => {
      // const formData = new FormData();
      // formData.append('audio', blob, 'audio.wav');

      try {
        const apiUrl = getApiUrl();

        const response = await axios.post(`${apiUrl}/transcribe`, {
          file,
          native_language: userProfile?.native_language,
        });

        return response.data.transcription;
      } catch (error) {
        console.error('Error transcribing audio:', error);
      }
    },
    [userProfile?.native_language],
  );

  const scrollToBottom = () => {
    const messageList = document.querySelector('.str-chat__list');
    if (messageList) {
      messageList.scrollTop = messageList.scrollHeight;
    }
  };

  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  const scTranscribeAudio = useCallback(async (audio: string) => {
    try {
      const apiUrl = getApiUrl();
      const response = await axios.post(
        `${apiUrl}/sc-transcribe`,
        { audio },
        {
          headers: { 'Content-Type': 'application/json' },
        },
      );
      console.log('response: ', response);
      return response.data;
    } catch (error) {
      console.error('Error transcribing audio:', error);
    }
  }, []);

  const overrideSubmitHandler: MessageInputProps<StreamChatGenerics>['overrideSubmitHandler'] =
    async (message, _, ...restSendParams) => {
      let updatedMessage = message;

      if (
        message.attachments &&
        message.attachments[0]?.type === 'voiceRecording'
      ) {
        const userAge = userProfile?.birthday
          ? calculateAge(userProfile.birthday)
          : undefined;

        const messageToSend = {
          ...updatedMessage,
          parent: updatedMessage.parent
            ? {
                ...updatedMessage.parent,
                created_at: updatedMessage.parent.created_at?.toString(),
                pinned_at: updatedMessage.parent.pinned_at?.toString(),
                updated_at: updatedMessage.parent.updated_at?.toString(),
              }
            : undefined,
        };

        const sendMessagePromise = (sendMessage as any)(messageToSend, {
          ...restSendParams,
          JA: '',
          EN: '',
          gender: userProfile?.gender,
          originalLanguage: userProfile?.native_language,
          originalText: '',

          translationSuccess: false,
          translationError: false,

          age: userAge,
          user: {
            ...client.user,
          },
        });

        await sendMessagePromise;

        const transcription = await transcribeAudio(
          message.attachments[0].asset_url as string,
        );

        if (transcription) {
          const messages = channel.state.messages;
          const lastMessage = messages[messages.length - 1];

          const { message: newMessage } = await client.updateMessage({
            ...(lastMessage as Message),
            JA: transcription,
            EN: transcription,
            text: transcription,
            gender: userProfile?.gender,
            originalLanguage: userProfile?.native_language,
            originalText: transcription,
            translationSuccess: false,
            translationError: false,
            age: userAge,
            user: {
              ...client.user,
            },
          });

          const translations = await translateMessage(
            transcription,
            newMessage.user?.name || 'Unknown User',
            // @ts-ignore
            lastMessage,
          );

          await client.updateMessage({
            ...newMessage,
            ...translations,
          });
        } else {
          if (
            message.attachments?.length &&
            message.text?.startsWith('/giphy')
          ) {
            updatedMessage = {
              ...message,
              text: message.text.replace('/giphy', ''),
            };
          }

          if (giphyState) {
            updatedMessage = { ...message, text: `/giphy ${message.text}` };
          }

          if (sendMessage) {
            const userAge = userProfile?.birthday
              ? calculateAge(userProfile.birthday)
              : undefined;

            const messageToSend = {
              ...updatedMessage,
              parent: updatedMessage.parent
                ? {
                    ...updatedMessage.parent,
                    created_at: updatedMessage.parent.created_at?.toString(),
                    pinned_at: updatedMessage.parent.pinned_at?.toString(),
                    updated_at: updatedMessage.parent.updated_at?.toString(),
                  }
                : undefined,
            };

            console.log('gender we got here', userProfile);

            const sendMessagePromise = (sendMessage as any)(messageToSend, {
              ...restSendParams,
              JA: message.text,
              EN: message.text,
              gender: userProfile?.gender,
              originalLanguage: userProfile?.native_language,
              originalText: message.text,

              // translationSuccess: false,
              // translationError: false,

              age: userAge,
              user: {
                ...client.user,
              },
            });

            try {
              await sendMessagePromise;

              const messages = channel.state.messages;
              const lastMessage = messages[messages.length - 1];

              if (typeof lastMessage.text === 'string') {
                const translations = await translateMessage(
                  lastMessage.text,
                  lastMessage.user?.name || 'Unknown User',
                  // @ts-ignore
                  lastMessage,
                );

                await client.updateMessage({
                  ...lastMessage,
                  ...translations,
                });

                /* updatePreviousMessages({
                text: lastMessage.text,
                userName: lastMessage.user?.name || 'Unknown User',
                gender: userProfile?.gender,
                age: userAge,
              }); */
              }

              console.log('Message updated with translations');
            } catch (error) {
              console.error('Error sending or translating message:', error);
            }

            logChatPromiseExecution(sendMessagePromise, 'send message');
          }

          setGiphyState(false);
        }
      } else {
        if (message.attachments?.length && message.text?.startsWith('/giphy')) {
          updatedMessage = {
            ...message,
            text: message.text.replace('/giphy', ''),
          };
        }

        if (giphyState) {
          updatedMessage = { ...message, text: `/giphy ${message.text}` };
        }

        if (sendMessage) {
          const userAge = userProfile?.birthday
            ? calculateAge(userProfile.birthday)
            : undefined;

          const messageToSend = {
            ...updatedMessage,
            parent: updatedMessage.parent
              ? {
                  ...updatedMessage.parent,
                  created_at: updatedMessage.parent.created_at?.toString(),
                  pinned_at: updatedMessage.parent.pinned_at?.toString(),
                  updated_at: updatedMessage.parent.updated_at?.toString(),
                }
              : undefined,
          };

          const sendMessagePromise = (sendMessage as any)(messageToSend, {
            ...restSendParams,
            JA: message.text,
            EN: message.text,
            gender: userProfile?.gender,
            originalLanguage: userProfile?.native_language,
            originalText: message.text,

            // translationSuccess: false,
            // translationError: false,

            age: userAge,
            user: {
              ...client.user,
            },
          });

          try {
            await sendMessagePromise;

            if (channel.data?.ai_assisted && channel.data?.is_support) {
              // alert('Should get message back!');

              const messages = channel.state.messages;

              const apiUrl = getApiUrl();
              axios.post(`${apiUrl}/persona`, {
                channelId: channel.id,
                messages: messages.slice(-10),
                native_language: userProfile?.native_language,
              });
            }

            const messages = channel.state.messages;
            const lastMessage = messages[messages.length - 1];
            console.log('lastMessage:', lastMessage);

            if (typeof lastMessage.text === 'string') {
              const translations = await translateMessage(
                lastMessage.text,
                lastMessage.user?.name || 'Unknown User',
                // @ts-ignore
                lastMessage,
              );

              await client.updateMessage({
                ...lastMessage,
                ...translations,
              });
            }

            console.log('Message updated with translations');
          } catch (error) {
            console.error('Error sending or translating message:', error);
          }

          logChatPromiseExecution(sendMessagePromise, 'send message');
        }

        setGiphyState(false);
      }
    };

  const actions = ['delete', 'react'];

  const customRenderMessages: MessageRenderer<StreamChatGenerics> = (props) => {
    const elements = defaultRenderMessages({ ...props, messages });
    const updatedElements = elements.map((element, index) => {
      const currentMessage = messages[index];
      if (currentMessage) {
        const messageText =
          chatLanguage === 'original'
            ? currentMessage.text
            : currentMessage[chatLanguage] || currentMessage.text;

        // Create a new React element with the updated text
        const updatedElement = React.cloneElement(element, {
          message: { ...currentMessage, text: messageText },
        });

        return updatedElement;
      }
      return element;
    });

    return updatedElements;
  };

  const onTranscriptionComplete = useCallback(
    async (transcription: string, filePath: string) => {
      const { message } = await channel.sendMessage({
        text: transcription,
        JA: transcription,
        EN: transcription,
        gender: userProfile?.gender,
        originalLanguage: userProfile?.native_language,
        originalText: transcription,
        translationSuccess: false,
        translationError: false,
        audioFilePath: filePath,
        age: userProfile?.birthday
          ? calculateAge(userProfile.birthday)
          : undefined,
      });

      const translations = await translateMessage(
        transcription,
        message.user?.name || 'Unknown User',
      );

      await client.updateMessage({
        ...message,
        ...translations,
      });
    },
    [channel, userProfile, client, translateMessage],
  );

  const [disabledInput, setDisabledInput] = useState(false);
  const renderInput = useCallback(() => {
    return <CustomInputComponent setDisabledInput={setDisabledInput} />;
  }, [setDisabledInput]);

  const renderMessage = useCallback(() => {
    return (
      <motion.div
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.3, delay: 0.2 }}
      >
        <MessageSimple />
      </motion.div>
    );
  }, []);

  return (
    <div
      style={{
        width: '100%',
      }}
    >
      <Window>
        <div className="messaging-channel-header-wrapper">
          <MessagingChannelHeader theme={theme} toggleMobile={toggleMobile} />
        </div>
        <MessageList
          messageActions={actions}
          // @ts-ignore
          renderMessages={customRenderMessages}
          Message={renderMessage}
          renderText={(text) => (
            <CustomRenderText text={text} messages={messages} />
          )}
        />

        <MessageListTypingIndicator />
        <div className="text-entry-box">
          <div className="flex items-center pr-2 bg-[#2e3033]">
            <MessageInput
              focus
              overrideSubmitHandler={overrideSubmitHandler}
              audioRecordingConfig={{
                transcoderConfig: { encoder: encodeToMp3 },
              }}
              Input={renderInput}
              audioRecordingEnabled // ={false}
              grow
              hideSendButton={disabledInput}
              asyncMessagesMultiSendEnabled={false}
            />

            {/* <VoiceRecordingWidget
              onTranscriptionComplete={onTranscriptionComplete}
            /> */}
          </div>
        </div>
      </Window>
      <Thread />
    </div>
  );
};

export default ChannelInner;
