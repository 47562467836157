import React, { useCallback, useEffect, useState } from 'react';
import DatePicker from 'react-datepicker'; // You'll need to install this package
import 'react-datepicker/dist/react-datepicker.css';
import { useTranslation } from 'react-i18next';
import { CgSpinner } from 'react-icons/cg';
import { useNavigate } from 'react-router-dom';
import { TranslationLanguages } from 'stream-chat';
import { useChatContext } from 'stream-chat-react';
import { Label } from '../@/components/ui/label';
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '../@/components/ui/select';
import { useToast } from '../hooks/useToast';
import { supabase } from '../supabaseClient';
import { StreamChatGenerics } from '../types';
import LanguageSelector from './LanguageSelector';
import LoginDeviceButton from './LoginDeviceButton/LoginDeviceButton';
import ProfilePictureUpload from './ProfilePictureUpload';
import Button from './shared/Button';
import Input from './shared/Input';
import SkeletonLoader from './SkeletonLoader/SkeletonLoader';

interface UserProfile {
  name: string;
  email: string;
  avatar_url: string | null;
  gender: string;
  birthday: Date | null;
}

const currentYear = new Date().getFullYear();
const years = Array.from({ length: 100 }, (_, i) => currentYear - i);

const Settings: React.FC = () => {
  const { t, i18n } = useTranslation();
  const { client } = useChatContext<StreamChatGenerics>();
  const [profile, setProfile] = useState<UserProfile>({
    name: '',
    email: '',
    avatar_url: null,
    gender: '',
    birthday: null,
  });
  const [isLoading, setIsLoading] = useState(true);
  const [isSaving, setIsSaving] = useState(false);
  const navigate = useNavigate();
  const toast = useToast();
  const [isAvatarLoading, setIsAvatarLoading] = useState(false);

  const fetchData = useCallback(async () => {
    setIsLoading(true);
    try {
      const {
        data: { user },
      } = await supabase.auth.getUser();
      if (user) {
        const { data, error } = await supabase
          .from('profiles')
          .select('name, avatar_url, gender, birthday')
          .eq('supabase_user_id', user.id)
          .single();

        if (error) {
          throw error;
        }
        setProfile({
          name: data?.name || '',
          email: user.email || '',
          avatar_url: data?.avatar_url || null,
          gender: data?.gender || '',
          birthday: data?.birthday ? new Date(data.birthday) : null,
        });
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    } finally {
      setIsLoading(false);
    }
  }, []);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const handleSave = async () => {
    setIsSaving(true);
    try {
      const {
        data: { user },
      } = await supabase.auth.getUser();
      if (!user) throw new Error(t('noAuthenticatedUser'));

      const { error } = await supabase
        .from('profiles')
        .update({
          name: profile.name,
          gender: profile.gender,
          birthday: profile.birthday?.toISOString().split('T')[0],
        })
        .eq('supabase_user_id', user.id);

      if (error) throw error;
      toast.success(t('profileUpdated'));
    } catch (error) {
      console.error('Error saving profile:', error);
      toast.error(t('failedToSaveProfile'));
    } finally {
      setIsSaving(false);
    }
  };

  const handleLogout = async () => {
    try {
      const { error } = await supabase.auth.signOut();
      client.disconnectUser();
      if (error) throw error;
      navigate('/login');
    } catch (error) {
      console.error('Error logging out:', error);
      toast.error(t('failedToLogOut'));
    }
  };

  const handleAvatarUpdate = (url: string) => {
    setProfile((prev) => ({ ...prev, avatar_url: url }));
    toast.success(t('profilePictureUpdated'));
  };

  const handleLanguageUpdate = (newLanguage: string) => {
    i18n.changeLanguage(newLanguage.toLowerCase());

    client.upsertUser({
      id: client.userID as string,
      language: newLanguage.toLowerCase() as TranslationLanguages,
    });

    toast.success(t('languageUpdated'));
    setTimeout(() => {
      window.location.reload();
    }, 1000);
  };

  return (
    <div className="w-full text-white p-4 bg-[#212326]">
      <h2 className="text-2xl font-bold mb-8">{t('settings')}</h2>
      {isLoading ? (
        <SkeletonLoader className="w-72" />
      ) : (
        <section className="max-w-2xl">
          <div className="mb-8">
            <label className="block text-sm font-medium mb-2 text-gray-400">
              {t('profilePicture')}
            </label>
            <div id="settings-img-upload" className="flex">
              <div className="w-35">
                {' '}
                {/* Adjust width as needed */}
                <ProfilePictureUpload
                  onAvatarUpdate={handleAvatarUpdate}
                  maxSize={128}
                  currentAvatarUrl={profile.avatar_url}
                />
              </div>
            </div>
          </div>
          <Input
            label={t('name')}
            value={profile.name}
            onChange={(newProfileName) =>
              setProfile({ ...profile, name: newProfileName })
            }
            className="mb-5"
          />
          <Input
            label={t('email')}
            value={profile.email}
            readOnly
            className="mb-5"
          />

          <div className="space-y-6 mb-5">
            <div className="space-y-2">
              <Label htmlFor="gender" className="text-gray-300">
                {t('gender')}
              </Label>
              <Select
                value={profile.gender}
                onValueChange={(value) =>
                  setProfile({ ...profile, gender: value })
                }
              >
                <SelectTrigger
                  id="gender"
                  className="bg-gray-600/50 border-gray-500 text-white w-min min-w-[160px]"
                >
                  <SelectValue placeholder={t('selectGender')} />
                </SelectTrigger>
                <SelectContent className="bg-gray-700 border-gray-600">
                  <SelectItem
                    value="male"
                    className="text-white data-[highlighted]:bg-blue-600 data-[highlighted]:text-white"
                  >
                    {t('male')}
                  </SelectItem>
                  <SelectItem
                    value="female"
                    className="text-white data-[highlighted]:bg-blue-600 data-[highlighted]:text-white"
                  >
                    {t('female')}
                  </SelectItem>
                  <SelectItem
                    value="gender_unspecified"
                    className="text-white data-[highlighted]:bg-blue-600 data-[highlighted]:text-white"
                  >
                    {t('preferNotToSay')}
                  </SelectItem>
                </SelectContent>
              </Select>
            </div>

            <div className="space-y-2">
              <Label htmlFor="birthYear" className="text-gray-300">
                {t('birthYear')}
              </Label>
              <Select
                value={
                  profile.birthday
                    ? new Date(profile.birthday.getTime() + 86400000)
                        .getFullYear()
                        .toString()
                    : undefined
                }
                onValueChange={(value) =>
                  setProfile({ ...profile, birthday: new Date(value) })
                }
              >
                <SelectTrigger
                  id="birthYear"
                  className="bg-gray-600/50 border-gray-500 text-white w-min min-w-[160px]"
                >
                  <SelectValue placeholder={t('selectYear')} />
                </SelectTrigger>
                <SelectContent className="bg-gray-700 border-gray-600 max-h-[300px]">
                  {years.map((year) => (
                    <SelectItem
                      key={year}
                      value={year.toString()}
                      className="text-white data-[highlighted]:bg-blue-600 data-[highlighted]:text-white"
                    >
                      {year}
                    </SelectItem>
                  ))}
                </SelectContent>
              </Select>
            </div>
          </div>

          <Button
            onClick={handleSave}
            disabled={isSaving}
            className="px-4 py-2 mb-8"
          >
            {isSaving ? t('saving') : t('saveChanges')}
          </Button>

          <div className="mb-5">
            <LanguageSelector onLanguageUpdate={handleLanguageUpdate} />
          </div>

          <div className="mb-5">
            <label className="block text-sm font-medium mb-2 text-gray-400">
              {t('loginNewDevice')}
            </label>
            <LoginDeviceButton />
          </div>

          <div className="mt-8">
            <Button
              onClick={handleLogout}
              className="bg-red-500 hover:bg-red-600 text-white px-4 py-2 rounded"
            >
              {t('logOut')}
            </Button>
          </div>
        </section>
      )}
    </div>
  );
};

export default Settings;
